<template>
  <div class="form-group">
    <label v-if="label">{{ label }} {{ required ? '*' : '' }}</label>
    <span @click="handleUpdate">
      <VueTrix
        v-if="!loading"
        ref="trixPreview"
        :value="modelValue"
        @input="updateValue"
        @update:modelValue="handleUpdate"
        :inputId="inputId"
        placeholder=""
      />
    </span>
  </div>
</template>

<script>
import Trix from "trix";
import fields from "./dynamic-quickpitch-fields.json";
import VueTrix from "./VueTrix.vue";

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
    body: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      default: "body"
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      default: "bodyTrix"
    }
  },
  data() {
    return {
      loading: false,
      position: "",
      lastValue: "",
    };
  },
  components: {
    VueTrix,
  },
  watch: {
    body: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.$refs.trixPreview?.$refs.trix && newValue !== this.lastValue) {
          this.$refs.trixPreview.$refs.trix.value = newValue;
          this.lastValue = newValue;
        }
      }
    }
  },
  mounted() {
    var vm = this;
    document.addEventListener("trix-initialize", function (event) {
      let input = document.getElementById(vm.inputId);
      if (event.target.inputElement != input) {
        return;
      }
      var dynamicTextButton = fields["dynamic-dropdown"];

      let trixToolbar = vm.$refs.trixPreview.$refs.trix.toolbarElement;
      trixToolbar
        .querySelector(".trix-button-group--text-tools")
        .insertAdjacentHTML("afterbegin", dynamicTextButton);

      $(".dropdown-toggle").dropdown();

      const initialValue = vm.modelValue || vm.body;
      if (initialValue && initialValue !== vm.lastValue) {
        vm.$refs.trixPreview.$refs.trix.value = initialValue;
        vm.lastValue = initialValue;
      }
    });

    document.addEventListener("trix-attachment-add", function (event) {
      if (event.attachment.attachment.preloadingURL) {
        event.attachment.remove();
        alert(
          "Image attachments are not supported. It is best to use simple text and links when initially reaching out to venues for booking consideration.",
        );
      }
    });
  },
  methods: {
    updateValue(value) {
      if (value !== this.lastValue) {
        this.lastValue = value;
        this.$emit('update:modelValue', value);
      }
    },
    handleUpdate(event) {
      if (!this.$refs.trixPreview?.$refs.trix?.editor) return;

      // Only handle dropdown updates, not text updates
      if (event.target && event.target.attributes["data-trix-attribute"]) {
        this.position = this.$refs.trixPreview.$refs.trix.editor.getPosition();

        let trixAttribute = event.target.attributes["data-trix-attribute"];
        let field = fields[trixAttribute.value];
        let attachment = new Trix.Attachment({ content: field });

        if (field) {
          this.$refs.trixPreview.$refs.trix.editor.setSelectedRange(this.position);
          this.$refs.trixPreview.$refs.trix.editor.insertAttachment(attachment);
        }

        if (trixAttribute.value == "booker") {
          this.$refs.trixPreview.$refs.trix.editor.insertString(",");
        }

        $(".dropdown-toggle").dropdown("hide");

        const newValue = this.$refs.trixPreview.$refs.trix.value;
        if (newValue !== this.lastValue) {
          this.lastValue = newValue;
          this.$emit("update:modelValue", newValue);
        }
      }
    },
  },
};
</script>
